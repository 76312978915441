import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import {
    heroProps,
    preFooterTitle,
    flexibleTextBlocks,
    cardsDataPrefooter,
    ReviewsPrefooter,
    packageIncludesProps,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import FlexibleModule from '../../lib/components/modules/flexible'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'

import backgroundImageMobile from '../../../resources/images/hanzo/testimonials/background_mobile.webp'
import backgroundImage from '../../../resources/images/hanzo/testimonials/background.webp'

const PricingPage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })
    return (
        <div className={styles.pricing_page}>
            <div className={styles.content}>
                <HeroModule
                    {...heroProps}
                    background={{
                        image: {
                            src: isMobile ? backgroundImageMobile : backgroundImage,
                            alt: 'pattern',
                        },
                    }}
                />
                <FlexibleModule hasBorder={false} textBlocks={flexibleTextBlocks} />
                <HeroModule {...packageIncludesProps} />
                <div className={styles.bg_grey_20}>
                    <PreFooter
                        title={preFooterTitle}
                        reviews={ReviewsPrefooter}
                        cardsData={cardsDataPrefooter}
                    />
                </div>
            </div>
        </div>
    )
}

export default PricingPage
