import React from 'react'
import { DemoIcon, DownloadBigIcon } from '../../lib/components/basics/icons'

// HERO PROPERTIES (Used in HeroModule component)
export const heroProps = {
    title: (
        <h2>
            All your Kafkas. <br />
            One experience.
        </h2>
    ),
    primaryCtaLink: '/request-quote',
    primaryCtaLabel: 'Get quote',
}

// FLEXIBLE MODULE PROPERTIES (Used in FlexibleModule component)
// Text blocks for flexible module (Used in FlexibleModule component)
export const flexibleTextBlocks = [
    {
        title: 'Community',
        description: `
            <p>For individuals & small teams beginning their data streaming journey.</p>
            <br/>
            <p>· Limited features</p>
            <p>· Basic Authentication</p>
            <p>· 2 user accounts</p>
            <p>· Connect up to 2 Kafka clusters</p>
            <p>· Supports all Kafka vendors</p>`,
        ctas: [
            {
                text: 'Download free',
                href: '/apache-kafka-docker',
            },
        ],
    },
    {
        title: 'Enterprise',
        description: `
            <p>For enterprises needing self-service control over a single Kafka cluster.</p>
            <br/>
            <p>· Full features</p>
            <p>· SSO Authentication</p>
            <p>· Unlimited users</p>
            <p>· 1 Lenses per Kafka cluster</p>
            <p>· Supports all Kafka vendors</p>`,
        ctas: [
            {
                text: 'Request Demo*',
                href: '/request-demo',
            },
        ],
    },
    {
        title: 'Enterprise Multi-Kafka',
        description: `
            <p>For enterprises that need a unified experience across a multi-vendor Kafka estate.</p>
            <br/>
            <p>· Full features</p>
            <p>· SSO Authentication</p>
            <p>· Unlimited users</p>
            <p>· 1 Lenses for all Kafka clusters</p>
            <p>· Supports all Kafka vendors</p>`,
        ctas: [
            {
                text: 'Request quote*',
                href: '/request-quote',
            },
        ],
    },
]

export const packageIncludesProps = {
    isLight: true,
    height: 'fit-content' as const,
    description: (
        <small>
            <b>* Add-on Packages:</b> Enterprise support for connectors | Topic backup and restore
        </small>
    ),
}

//PREFOOTER
export const preFooterTitle = 'One product. Unlimited productivity.'

export const ReviewsPrefooter = [
    {
        review: 'Lenses is amazing at providing insights into the Kafka topics',
        author: 'Alexandru-Florian A',
    },
    {
        review: 'The Lenses product is amazing and I am positive it has drastically increased our development times using Kafka.',
        author: 'Matthew G.',
    },
    {
        review: 'Beautiful, responsive UI for kafka overview and with lenses SQL for kafka topics browsing',
        author: 'Ivan V.',
    },
    {
        review: "Lenses provides a quick inside view of all your data flow. There's not much to dislike.",
        author: 'Kyriakos P.',
    },
    {
        review: 'Lenses is an intuitive tool that provides great observability and management features for our streaming',
        author: 'Anonymous',
    },
    {
        review: 'A very respectable add-on for enterprise-grade Kafka clusters!',
        author: 'Donny F.',
    },
    { review: 'Invaluable tool for anyone using Kafka', author: 'Michael G.' },
    {
        review: `Very complete tool to browse and understand what's happening inside your clusters`,
        author: 'George F.',
    },
]

export const cardsDataPrefooter = [
    {
        title: '20 minutes<br />demo,<br />7 days trial',
        type: 'primary' as const,
        description: [
            'Guided tour of Lenses',
            'Trial key to install on your clusters',
            'Great for production and large-scale Kafka deployments',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
    {
        title: 'Free Kafka<br />Sandbox +<br />Lenses',
        type: 'secondary' as const,
        description: [
            'Localhost Kafka docker with Lenses',
            'Complete container to build streaming apps',
            'Great as a dev/ pre-prod environment',
        ],
        buttonText: 'Download',
        href: '/apache-kafka-docker',
        icon: <DownloadBigIcon key='downloadBigIcon' />,
    },
]
